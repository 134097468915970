// Import Icons
import chatIcon from '../../../assets/FavIcons/favicon.png';

// TempData
import TempDataNavBar from '../TempData_NavBar';
import TempDataMegaMenu from '../TempData_MegaMenu';
import TempDataFooter from '../TempData_footer';
import * as ColorGuide from '../../../cssGuides/colorGuide';
// Page Data
const Data = {
  pageName: 'IconCard Test PAge',
  pageTemplate: 'Template3',
  pageData: {
    nav: TempDataNavBar,
    megaMenu: TempDataMegaMenu,
    footer: TempDataFooter,
    level_1_Id: 'Page_1',
    pageTitle: 'HTML render test page',
    pageDescription: 'Tester component - HTML render',
    bannerImage: null, // accepts null
    bannerContent: '<h1>Banner text accepts HTML</h1>', // accepts null
    bottomContent: [
      {
        componentName: 'BlockArea',
        componentData: {
          backgroundColor: ColorGuide.aqua,
        },
        componentChildren: [
          {
            componentName: 'Columns',
            componentData: {
              columns: 2,
              gap: '40',
              minWidth: '1024px',
            },
            componentChildren: [
              {
                componentName: 'IconCard',
                componentData: {
                  id: 1,
                  icon: chatIcon,
                  iconAltText: 'Sampension FavIcon',
                  header: 'Få overblik over din pension',
                  text:
                    'Dan dig nemt og hurtigt et overblik over din pension og se hvor godt du er stillet i forhold til din pension',
                  url: 'http://www.google.com',
                },
              },
              {
                componentName: 'IconCard',
                componentData: {
                  id: 1,
                  icon: chatIcon,
                  iconAltText: 'Sampension FavIcon',
                  header: 'Få overblik over din pension',
                  text:
                    'Dan dig nemt og hurtigt et overblik over din pension og se hvor godt du er stillet i forhold til din pension',
                  url: '/item1',
                },
              },
            ],
          },
        ],
      },
    ],
    desktopTools: {}, // accepts null
  },
  pageContent: [
    {
      componentName: 'IconCard',
      componentData: {
        id: 1,
        icon: chatIcon,
        iconAltText: 'Sampension FavIcon',
        header: 'Få overblik over din pension',
        text:
          'Dan dig nemt og hurtigt et overblik over din pension og se hvor godt du er stillet i forhold til din pension',
        url: '/item1',
      },
    },
  ],
};

export default Data;
